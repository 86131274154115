<template>
    <div class="w-100">
        <!-- <Card
            data-cy="reportsGeneralInformation-comprehensionGraph-card"
            :title="$t('reports.dashboard.cards.readingComprehension')"
            :tooltipMsg="$t('reports.dashboard.tooltips.generalInformation.readingComprehension')"
        /> -->
        <Card
            data-cy="reportsGeneralInformation-questionsTable-card"
            :title="$t('reports.dashboard.cards.questionsAmountquestionsAmount')"
            :tooltipMsg="$t('reports.dashboard.tooltips.generalInformation.questionsAmount')"
        >
            <template v-slot:content>
                <Table
                    :headers="generateHeadersForNFQTable"
                    :items="generateItemsForNFQTable"
                    :loading="isLoadingData"
                    withNumberOfQuestions
                />
            </template>
        </Card>
        <Card
            data-cy="reportsGeneralInformation-performanceTable-card"
            :title="$t('reports.dashboard.cards.studentsPerformance')"
            :tooltipMsg="$t('reports.dashboard.tooltips.generalInformation.studentsPerformance')"
        >
            <template v-slot:content>
                <Table
                    :headers="generateHeadersForPFSTable"
                    :sortByHeaders="sortPFSTableByHeaders"
                    :sortByDesc="sortPFSTableHedersInDesc"
                    :items="generateItemsForPFSTable"
                    :studentNameCallback="setSelectedStudent"
                    :loading="isLoadingData"
                    withPerformanceSkills
                    withTierData
                    withStudent
                />
            </template>
        </Card>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import MapStudentReportsData from '@/mixins/reports/mapStudentsData'
import Card from '../shared/card/index.vue'
import Table from '../shared/table/index.vue'

export default {
    name: 'GeneralInformation',
    mixins: [MapStudentReportsData],
    components: { Card, Table },
    data: () => ({
        currentGeneralInformation: [],
        currentPFSData: [],
        currentNFQData: [],
        sortPFSTableByHeaders: [
            'readingComprehension'
        ],
        sortPFSTableHedersInDesc: [true]
    }),
    computed: {
        ...mapState('reports', [
            'studentsPerCourse',
            'courseSelected',
            'studentSelected',
            'generalInformation',
            'dateOptionSelected',
            'tierSelected',
            'dates'
        ]),
        ...mapGetters('reports', ['studentsFromCourse', 'isLoadingData']),
        generateHeadersForNFQTable() {
            return [
                {
                    text: this.$t('reports.tableHeaders.activities'),
                    align: 'start',
                    sortable: false,
                    value: 'lectures',
                },
                {
                    text: this.$t('reports.tableHeaders.amount'),
                    align: 'center',
                    sortable: true,
                    value: 'amount',
                },
                {
                    text: this.$t('reports.tableHeaders.corrects'),
                    align: 'center',
                    sortable: true,
                    value: 'corrects',
                },
                {
                    text: this.$t('reports.tableHeaders.incorrects'),
                    align: 'center',
                    sortable: true,
                    value: 'incorrects',
                },
                {
                    text: this.$t('reports.tableHeaders.total'),
                    align: 'center',
                    sortable: true,
                    value: 'total',
                },
            ]
        },
        generateItemsForNFQTable() {
            return this.currentNFQData
        },
        generateHeadersForPFSTable() {
            return [
                {
                    text: this.$t('reports.tableHeaders.student'),
                    align: 'start',
                    sortable: false,
                    value: 'student',
                    class: 'text-no-wrap'
                },
                {
                    text: this.$t('reports.tableHeaders.readerType'),
                    align: 'center',
                    sortable: false,
                    value: 'type',
                    class: 'text-no-wrap'
                },
                {
                    text: this.$t('reports.skills.fullName.locate'),
                    align: 'center',
                    sortable: false,
                    value: 'locate',
                    width: '13%'
                },
                {
                    text: this.$t('reports.skills.fullName.integrate'),
                    align: 'center',
                    sortable: false,
                    value: 'integrate',
                    width: '13%'
                },
                {
                    text: this.$t('reports.skills.fullName.reflect'),
                    align: 'center',
                    sortable: false,
                    value: 'reflect',
                    width: '13%'
                },
                {
                    text: this.$t('reports.dashboard.cards.readingComprehension'),
                    align: 'center',
                    sortable: true,
                    value: 'readingComprehension',
                    class: 'text-no-wrap',
                    width: '13%'
                },
                {
                    text: this.$t('reports.tableHeaders.beereadersStage'),
                    align: 'center',
                    sortable: true,
                    value: 'beeStage',
                    class: 'text-no-wrap'
                },
            ]
        },
        generateItemsForPFSTable() {
            return this.currentPFSData
        },
    },
    watch: {
        async courseSelected() {
            if (!this.courseSelected) return
            await this.setDefaultData()
        },
        async dateOptionSelected() {
            if (!this.courseSelected) return
            await this.updateCurrentGeneralInformation()

            await this.setPFSStudentsData()
            await this.setNFQStudentsData()
        },
        async studentSelected() {
            if (!this.courseSelected) return
            await this.setPFSStudentsData()
            await this.setNFQStudentsData()
        },
        async tierSelected() {
            await this.setPFSStudentsData()
            await this.setNFQStudentsData()
        },
        'dates.initialDate': async function () {
            if (this.dateOptionSelected === 0) await this.setDefaultData()
        },
        'dates.endDate': async function () {
            if (this.dateOptionSelected === 0) await this.setDefaultData()
        },
    },
    methods: {
        ...mapActions('reports', [
            'getGeneralInformationFromCourse',
            'setDefaultCourse',
            'setStudentsPerCourse',
            'setSelectedStudent'
        ]),
        async setDefaultData() {
          if (!this.dates || !this.dates?.endDate || !this.dates?.initialDate) return

            await this.updateCurrentGeneralInformation()

            await this.setPFSStudentsData()
            await this.setNFQStudentsData()
        },
        async setPFSStudentsData() {
            if (this.studentSelected) {
 this.currentPFSData = this.getStudentPFSData(
                    this.studentSelected,
                    this.currentGeneralInformation
                )
} else {
                this.currentPFSData = this.getAllPFSData(
                    this.studentsFromCourse(this.courseSelected.id),
                    this.currentGeneralInformation
                )
                if (this.tierSelected && this.tierSelected.tier != 0) {
 this.currentPFSData = this.currentPFSData.filter(
                        (PFSData) => PFSData.tier === this.tierSelected.tier
                    )
}
            }
        },
        async setNFQStudentsData() {
            if (this.studentSelected) {
 this.currentNFQData = this.getStudentNQFData(
                    this.studentSelected,
                    this.currentGeneralInformation
                )
} else {
 this.currentNFQData = this.tierSelected && this.tierSelected.tier != 0
                        ? this.getAllNFQData(this.currentGeneralInformation, this.tierSelected.tier)
                        : this.getAllNFQData(this.currentGeneralInformation)
}
        },
        async updateCurrentGeneralInformation() {
            if (!this.dates || !this.dates?.endDate || !this.dates?.initialDate) return

            const generalInformation = (await this.getGeneralInformationFromCourse({
                    courseID: this.courseSelected.id,
                    dateRangeOption: this.dateOptionSelected,
                })) || []

            this.currentGeneralInformation = generalInformation
        },
    },
    mounted() {
        this.setDefaultData()
    }
}
</script>

<style>
.text-no-wrap {
  word-break: keep-all;
}
</style>

<template>
    <div class="w-100">
        <Card
            data-cy="reportsBooks-booksAssignedTable-card"
            :title="$t('reports.dashboard.cards.booksByAssignment')"
            :tooltipMsg="$t('reports.dashboard.tooltips.booksInformation.booksByAssignment')"
        >
            <template v-slot:content>
                <Table
                    :headers="generateHeadersForABTable"
                    :items="generateItemsForABTable"
                    :loading="isLoadingData"
                    withPerformanceSkills
                    withReadingTime
                    withBookData
                />
            </template>
        </Card>
        <v-row cols="12">
            <v-col cols="6">
                <Card
                    data-cy="reportsBooks-resultsGraph-card"
                    :title="$t('reports.dashboard.cards.assignedBooksResults')"
                    :tooltipMsg="
                        $t('reports.dashboard.tooltips.booksInformation.assignedBooksResults')
                    "
                >
                    <template v-slot:content>
                        <BarChart
                            v-if="renderGraph"
                            :bars="graphData.bars"
                            :data="generateGraphData"
                        />
                        <span v-else>
                            {{ $t('general.loading') }}
                        </span>
                    </template>
                </Card>
            </v-col>
            <v-col cols="6" class="pb-7">
                <Card
                    data-cy="reportsBooks-questionsTable-card"
                    :title="$t('reports.dashboard.cards.questionsAmount')"
                    :tooltipMsg="$t('reports.dashboard.tooltips.booksInformation.questionsAmount')"
                    fullHeight
                >
                    <template v-slot:content>
                        <Table
                            :headers="generateHeadersForNOfQTable"
                            :items="generateItemsForNOfQTable"
                            :loading="isLoadingData"
                            withQuestionsAmount
                        />
                    </template>
                </Card>
            </v-col>
        </v-row>
        <Card
            data-cy="reportsBooks-selfLecturesTable-card"
            :title="$t('reports.dashboard.cards.selfLectures')"
            :tooltipMsg="$t('reports.dashboard.tooltips.booksInformation.selfLectures')"
            v-if="!isStudentSelected"
        >
            <template v-slot:content>
                <Table
                    :headers="generateHeadersForOTBTable"
                    :items="generateItemsForOTBTable"
                    :studentNameCallback="setSelectedStudent"
                    :loading="isLoadingData"
                    withStudent
                    withReadingTime
                />
            </template>
        </Card>
        <Card
            data-cy="reportsBooks-selfLecturesBooksTable-card"
            :title="$t('reports.dashboard.cards.selfLecturesBooks')"
            :tooltipMsg="$t('reports.dashboard.tooltips.booksInformation.selfLectures')"
            v-else
        >
            <template v-slot:content>
                <Table
                    :headers="generateHeadersForABTable"
                    :items="selfLectureBooks"
                    :loading="isLoadingData"
                    withPerformanceSkills
                    withReadingTime
                    withBookData
                    selfLectureBooks
                />
            </template>
        </Card>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import mapBooksInformationData from '@/mixins/reports/mapBooksInformationData'
import Card from '../shared/card/index.vue'
import Table from '../shared/table/index.vue'
import BarChart from '../shared/graphs/barChart/index.vue'

export default {
    name: 'Books',
    components: { Card, Table, BarChart },
    mixins: [mapBooksInformationData],
    data() {
        return {
            graphData: {
                bars: [
                    this.$t('reports.skills.abbrevationName.locate'),
                    this.$t('reports.skills.abbrevationName.integrate'),
                    this.$t('reports.skills.abbrevationName.reflect'),
                    this.$t('reports.skills.abbrevationName.readingComprehension'),
                ],
                data: [],
            },
            currentBookInformation: [],
            currentBooksAssigned: [],
            selfLectureBooks: [],
            currentBooksAssignedYourself: [],
            currentBooksNumberOfQuestions: [],
            currentGraphData: [],
            shouldGraphRender: false,
            isLoadingSelfLecturesBook: false
        }
    },
    computed: {
        ...mapState('reports', [
            'courseSelected',
            'studentSelected',
            'studentsPerCourse',
            'tierSelected',
            'bookInformation',
            'dateOptionSelected',
            'dates',
        ]),
        ...mapGetters('reports', [
            'studentsFromCourse',
            'getCourseBooksInformation',
            'isLoadingData',
        ]),
        generateHeadersForABTable() {
            return [
                {
                    text: this.$t('reports.tableHeaders.bookTitle'),
                    align: 'center',
                    sortable: false,
                    value: 'assignment',
                    class: 'text-no-wrap',
                },
                {
                    text: this.$t('reports.tableHeaders.lexileMeasure'),
                    align: 'center',
                    sortable: false,
                    value: 'lexileMeasure',
                },
                {
                    text: this.$t('reports.tableHeaders.progress'),
                    align: 'center',
                    sortable: true,
                    value: 'bookProgress',
                    class: 'text-no-wrap',
                    width: '12%'
                },
                {
                    text: this.$t('reports.skills.fullName.readingTime'),
                    align: 'center',
                    sortable: true,
                    value: 'readingTime',
                    width: '13%'
                },
                {
                    text: this.$t('reports.skills.fullName.locate'),
                    align: 'center',
                    sortable: false,
                    value: 'locate',
                    width: '12%'
                },
                {
                    text: this.$t('reports.skills.fullName.integrate'),
                    align: 'center',
                    sortable: false,
                    value: 'integrate',
                    width: '12%'
                },
                {
                    text: this.$t('reports.skills.fullName.reflect'),
                    align: 'center',
                    sortable: false,
                    value: 'reflect',
                    width: '12%'
                },
                {
                    text: this.$t('reports.dashboard.cards.readingComprehension'),
                    align: 'center',
                    sortable: true,
                    value: 'readingComprehension',
                    width: '12%'
                },
            ]
        },
        generateItemsForABTable() {
            return this.currentBooksAssigned
        },
        geteneralItemsForSelfLecturesTable(){
            return this.selfLectureBooks
        },
        generateHeadersForNOfQTable() {
            return [
                {
                    text: this.$t('reports.tableHeaders.skill'),
                    align: 'center',
                    sortable: false,
                    value: 'skill',
                },
                {
                    text: this.$t('reports.tableHeaders.corrects'),
                    align: 'center',
                    sortable: false,
                    value: 'corrects',
                },
                {
                    text: this.$t('reports.tableHeaders.incorrects'),
                    align: 'center',
                    sortable: false,
                    value: 'incorrects',
                },
                {
                    text: this.$t('reports.tableHeaders.total'),
                    align: 'center',
                    sortable: false,
                    value: 'total',
                },
            ]
        },
        generateItemsForNOfQTable() {
            return this.currentBooksNumberOfQuestions
        },
        generateHeadersForOTBTable() {
            return [
                {
                    text: this.$t('reports.tableHeaders.student'),
                    align: 'start',
                    sortable: false,
                    value: 'student',
                    class: 'text-no-wrap'
                },
                {
                    text: this.$t('reports.tableHeaders.booksAmount'),
                    align: 'center',
                    sortable: true,
                    value: 'booksAmount',
                    class: 'text-no-wrap'
                },
                {
                    text: this.$t('reports.skills.fullName.readingTime'),
                    align: 'center',
                    sortable: true,
                    value: 'readingTime',
                    class: 'text-no-wrap'
                },
            ]
        },
        generateItemsForOTBTable() {
            return this.currentBooksAssignedYourself
        },
        renderGraph() {
            return !this.isLoadingData && this.shouldGraphRender
        },
        generateGraphData() {
            return this.currentGraphData
        },
        isStudentSelected() {
            if(!this.studentSelected || this.studentSelected.id === 0) return false
            return true
        }
    },
    watch: {
        async courseSelected() {
            await this.setDefaultData()
        },
        async tierSelected() {
            await this.updateBooksAssigmentsYourself()
        },
        async studentSelected() {
            await this.updateBooksAssigmentsYourself()
        },
        async dateOptionSelected() {
            if (!this.courseSelected) return
            await this.updateCurrentBookInformation()

            this.updateBooksAssigments()
            this.updateBooksAssigmentsYourself()
            this.udpateBooksNumberOfQuestions()
        },
        'dates.initialDate': async function () {
            if (this.dateOptionSelected === 0) await this.setDefaultData()
        },
        'dates.endDate': async function () {
            if (this.dateOptionSelected === 0) await this.setDefaultData()
        },
    },
    methods: {
        ...mapActions('reports', ['getBooksInformationFromCourse', 'setSelectedStudent', 'getCurrentStudentSelfLecturesInformation']),
        async updateBooksAssigments() {
            this.currentBooksAssigned = await this.getAssignedBooks(
                this.currentBookInformation?.assignedBooksMetricsList || []
            )
        },
        async updateBooksAssigmentsYourself() {
            if (this.studentSelected && this.studentSelected.id !== 0) {
                const individualBooksInformation = await this.getCurrentStudentSelfLecturesInformation()

                this.currentBookInformation = individualBooksInformation

                this.selfLectureBooks = await this.getAssignedBooks(
                    individualBooksInformation.assignedYourselfBooksByStudentMetricsList || []
                )

                await this.updateBooksAssigments()
                await this.udpateBooksNumberOfQuestions()
                await this.updateGraphData()

            } else {
                await this.updateCurrentBookInformation()
                await this.updateBooksAssigments()
                await this.udpateBooksNumberOfQuestions()
                await this.updateGraphData()
                this.currentBooksAssignedYourself =
                    this.tierSelected && this.tierSelected.tier != 0
                        ? this.getAllAssignedYourselfBooks(
                              this.studentsFromCourse(this.courseSelected.id),
                              this.currentBookInformation?.assignedYourselfBooksMetricsList || [],
                              this.tierSelected.tier
                          )
                        : this.getAllAssignedYourselfBooks(
                              this.studentsFromCourse(this.courseSelected.id),
                              this.currentBookInformation?.assignedYourselfBooksMetricsList || []
                          )
            }
        },
        udpateBooksNumberOfQuestions() {
            this.currentBooksNumberOfQuestions = this.getBooksAmountOfQuestions(
                this.currentBookInformation?.assignedBooksMetricsList || []
            )
        },
        async updateCurrentBookInformation() {
            if (!this.dates || !this.dates?.endDate || !this.dates?.initialDate) return

            const bookInformation = await this.getBooksInformationFromCourse({
                courseID: this.courseSelected.id,
                dateRangeOption: this.dateOptionSelected,
            })

            this.currentBookInformation = bookInformation
        },
        updateGraphData() {
            this.shouldGraphRender = false
            this.currentGraphData = this.getBookResultsGraph(
                this.currentBookInformation?.assignedBooksMetricsList || []
            )

            setTimeout(() => (this.shouldGraphRender = true), 0)
        },
        async setDefaultData() {
            await this.updateCurrentBookInformation()

            await this.updateBooksAssigments()
            await this.updateBooksAssigmentsYourself()
            await this.udpateBooksNumberOfQuestions()
            this.updateGraphData()
        },
    },
}
</script>

<style>
.text-no-wrap {
  word-break: keep-all;
}
.text-wrap-all {
  word-break:normal;
}
</style>

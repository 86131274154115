<template>
    <div class="text-center m-0">
        <v-menu offset-y bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    color="primary"
                    dark
                    elevation="0"
                    v-on="on"
                    v-bind="{ attrs }"
                    :loading="isDownloading"
                >
                    <v-icon color="white" small>mdi-download</v-icon>
                    <span class="body-1 white--text font-weight-medium text-capitalize">
                        {{ $t('actions.export') }}
                    </span>
                </v-btn>
            </template>

            <v-list>
                <v-list-item v-for="(option, index) in formatOptions" :key="index" class="w-100">
                    <v-row class="w-100">
                        <v-btn
                            text
                            color="deep-purple"
                            class="w-100 text-align-start d-flex align-items-start justify-content-start py-3"
                            @click="option.action"
                        >
                            <v-row align-content="center" justify="start" class="text-start pa-3">
                                <v-icon small class="mr-1">
                                    {{ option.icon }}
                                </v-icon>
                                {{ option.text }}
                            </v-row>
                        </v-btn>
                    </v-row>
                </v-list-item>
            </v-list>
        </v-menu>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import * as Sentry from '@sentry/vue'

export default {
    name: 'ExportTableButton',
    data() {
        return {
            currentFormatOption: 0,
            isDownloading: false,
            formatOptions: [
                {
                    id: 1,
                    format: 'EXCEL',
                    text: 'Excel (xlsx)',
                    action: this.exportTableToExcel,
                    icon: 'mdi-file-excel-box',
                },
            ],
        }
    },
    computed: {
        ...mapState('reports', ['courseSelected', 'currentTab', 'dates']),
    },
    methods: {
        ...mapActions('reports', ['getExcelFile']),
        getCurrentTabName() {
            let currentTabName
            switch (this.currentTab) {
                case 0:
                    currentTabName = 'general-information'
                    break
                case 1:
                    currentTabName = 'books'
                    break
                case 2:
                    currentTabName = 'challenges'
                    break
            }

            return currentTabName
        },
        async exportTableToPDF() {
            this.isDownloading = true
            const data = await this.getExcelFile()
            this.isDownloading = false
        },
        s2ab(string) {
            const buf = new ArrayBuffer(string.length)
            const view = new Uint8Array(buf)
            for (let i = 0; i !== string.length; ++i) {
                view[i] = string.charCodeAt(i) & 0xff
            }
            return buf
        },
        async exportTableToExcel() {
            try {
                this.isDownloading = true
                const resp = await this.getExcelFile()
                const tabName = this.getCurrentTabName()
                const dateRange = `${this.$moment(this.dates.initialDate).format('YYYY-MM-DD')}_${this.$moment(this.dates.endDate).format('YYYY-MM-DD')}`
                const outputFilename = `${this.courseSelected.name}_${tabName}_${dateRange}.xlsx`

                const url = URL.createObjectURL(new Blob([resp]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', outputFilename)
                document.body.appendChild(link)
                link.click()
                this.isDownloading = false
            } catch (error) {
                Sentry.captureException(error)
                this.isDownloading = false
            }
        },
    },
}
</script>

<template>
    <v-row class="d-flex flex-column px-5">
        <span
            class="grey--text lighten-3 body-2 font-weight-medium"
            data-cy="generalFilter-datesTitle-span"
        >
            {{ $t('reports.generalFilter.filters.dateRange') }}
        </span>
        <v-select
            outlined
            :items="dateOptions"
            v-model="dateSelected"
            item-value="value"
            item-text="text"
            color="primary"
            prepend-inner-icon="mdi-calendar"
            hide-details
            hidden
            no-data-text="There is no students"
            placeholder="Select a student"
            dense
            small-chips
            data-cy="generalFilter-dateSelect-select"
        >
            <template v-slot:prepend-item>
                <v-col class="pa-3">
                    <span
                        class="body-2 font-weight-bold"
                        data-cy="generalFilter-dateDetailTitle-span"
                    >
                        {{ $t('reports.generalFilter.placeholders.dateRange') }}
                    </span>
                </v-col>
            </template>

            <template v-slot:item="{ item }">
                <v-radio-group v-model="dateSelected">
                    <v-radio color="primary" :value="item.value"></v-radio>
                </v-radio-group>
                <div class="d-flex flex-column">
                    <span class="body-2 font-weight-regular mv-2">{{ item.text }}</span>
                    <v-row v-if="item.id === 0">
                        <!-- Range date picker -->
                        <v-col>
                            <v-menu
                                ref="menu"
                                v-model="menu"
                                :close-on-content-click="false"
                                :return-value.sync="rangeDate"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                                dense
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="rangeDate"
                                        prepend-inner-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        outlined
                                        hide-details
                                        dense
                                        :placeholder="
                                            $t('reports.generalFilter.placeholders.dateRange')
                                        "
                                    >
                                    </v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="rangeDate"
                                    no-title
                                    color="primary"
                                    range
                                    :locale="$i18n.locale == 'en' ? 'en-us' : 'es'"
                                >
                                    <v-spacer></v-spacer>
                                    <v-btn text color="primary" @click="menu = false">
                                        {{ $t('reports.utils.cancel') }}
                                    </v-btn>
                                    <v-btn text color="primary" @click="$refs.menu.save(rangeDate)">
                                        OK
                                    </v-btn>
                                </v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row>
                </div>
            </template>
            <template v-slot:selection="{ item }">
                <v-chip small label color="primary white--text" class="white--text">{{
                    item.text
                }}</v-chip>
            </template>
        </v-select>
    </v-row>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
    name: 'DatePicker',
    data() {
        return {
            rangeDate: null,
            dateSelected: null,
            menu: null,
        }
    },
    computed: {
        ...mapState('reports', ['courseSelected', 'dates']),
        dateOptions() {
            return [
                {
                    id: 0,
                    text: this.$t('reports.generalFilter.defaultValues.customDates'),
                    value: 0,
                },
                {
                    id: 1,
                    text: this.$t('reports.generalFilter.defaultValues.last7Days'),
                    value: 7,
                },
                {
                    id: 2,
                    text: this.$t('reports.generalFilter.defaultValues.last30Days'),
                    value: 30,
                },
                {
                    id: 3,
                    text: this.$t('reports.generalFilter.defaultValues.last90Days'),
                    value: 90,
                },
            ]
        }
    },
    methods: {
        ...mapActions('reports', ['setNewDateRange', 'setNewCustomDateRange', 'setError', 'clearErrors']),
        async setCustomDates() {
            this.setNewDateRange(0)
            const initialDate = this.rangeDate[0]
            const endDate = this.rangeDate[1]

            if (initialDate > endDate) {
                this.setError({
                    type: 'filter',
                    error: this.$t('reports.errors.filterError.initialDateGreater'),
                })
                this.setNewCustomDateRange({ initialDate: undefined, endDate: undefined })
                return
            }

            this.clearErrors()
            await this.setNewCustomDateRange({ initialDate, endDate })
        },
        handleDefaultOptions() {
            this.dateSelected = this.dateOptions[1].value
        },
    },
    watch: {
        async dateSelected(rangeDays) {
            if (rangeDays != 0) {
                await this.setNewDateRange(rangeDays)
                return
            }

            if (this.rangeDate != null) this.setCustomDates()
        },
        rangeDate() {
            // If the option selected it's the custom dates
            if (this.dateSelected === 0) {
                const initialDate = this.rangeDate[0]
                const endDate = this.rangeDate[1]
                if (initialDate > endDate) {
                    this.setError({
                        type: 'filter',
                        error: 'The initial date must be before than the end date',
                    })
                    this.setNewCustomDateRange({ initialDate: undefined, endDate: undefined })
                    return
                }
                this.setNewCustomDateRange({ initialDate: undefined, endDate: undefined })
                this.setCustomDates()
            }
        },
    },
    async beforeMount() {
        await this.handleDefaultOptions()
    },
}
</script>

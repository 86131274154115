<template>
    <v-card
        flat
        elevation="0"
        rounded="xl"
        class="mb-2 d-flex flex-row row-nowrap align-center justify-center py-7 px-5"
        data-cy="generalFilter-container-article"
    >
        <v-row align-content="center" justify="center">
            <v-col cols="8">
                <v-row>
                    <!-- ----------------- -->
                    <!-- Filter by course -->
                    <!-- ----------------- -->
                    <v-col cols="4">
                        <CoursePicker />
                    </v-col>

                    <!-- ----------------- -->
                    <!-- Filter by tiers -->
                    <!-- TODO: remove it after tier dropdown refactor -->
                    <!-- ----------------- -->
                    <!-- <v-col cols="3">
                        <TierPicker />
                    </v-col> -->

                    <!-- ----------------- -->
                    <!-- Filter by students -->
                    <!-- ----------------- -->
                    <v-col cols="3">
                        <StudentPicker />
                    </v-col>
                </v-row>
            </v-col>
            
            <v-spacer></v-spacer>

            <!-- ----------------- -->
            <!-- Filter by date -->
            <!-- ----------------- -->
            <v-col cols="4">
                <v-row align-content="center" justify="center">
                    <v-col cols="7" class="mx-0 px-0">
                       <DatePicker />
                    </v-col>

                    <!-- Export button -->
                    <v-col cols="3" class="d-flex align-center justify-center-2 ma-0 pa-0 mt-5">
                        <ExportTableButton />
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
import { mapActions } from 'vuex'

import CoursePicker from './coursePicker/index.vue'
import TierPicker from './tierPicker/index.vue'
import DatePicker from './datePicker/index.vue'
import StudentPicker from './studentPicker/index.vue'
import ExportTableButton from './exportTableButton/index.vue'

export default {
    name: 'GeneralFilter',
    components: {
        CoursePicker,
        TierPicker,
        StudentPicker,
        DatePicker,
        ExportTableButton
    },
    mounted() {
      // TODO: remove it after tier dropdown refactor
      this.setTierSelected({
        id: 1,
        tier: 0,
        name: this.$t('reports.generalFilter.defaultValues.all')
      })
    },
    methods: {
       // TODO: remove it after tier dropdown refactor
      ...mapActions('reports', ['setTierSelected']),
    }
}
</script>

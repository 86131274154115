<template>
    <v-row class="d-flex flex-column">
        <span
            class="grey--text lighten-3 body-2 font-weight-medium"
            data-cy="generalFilter-tiersTitle-span"
        >
            {{ $t('reports.generalFilter.filters.levels') }}
        </span>
        <!-- Tiers Select Dropdown -->
        <v-select
            outlined
            :items="tiersObject"
            v-model="currentTierSelected"
            item-value="tier"
            item-text="name"
            color="primary"
            prepend-inner-icon="mdi-layers-triple"
            hide-details
            small-chips
            hidden
            no-data-text="There is no levels"
            placeholder="Select a level"
            dense
            data-cy="generalFilter-tierSelect-select"
        >
            <template v-slot:prepend-item>
                <v-col class="pa-3">
                    <span
                        class="body-2 font-weight-bold"
                        data-cy="generalFilter-tierDetailTitle-span"
                    >
                        {{ $t('reports.generalFilter.labels.filterByLevels') }}
                    </span>
                </v-col>
            </template>

            <template v-slot:selection="{ item }">
                <div color="deep-purple lighten-5" class="w-100">
                    <v-chip
                        small
                        label
                        color="deep-purple lighten-5 deep-purple--text"
                        v-if="item.tier === 0"
                        >{{ item.name }}</v-chip
                    >
                    <v-chip v-else small label color="primary white--text" class="white--text">{{
                        item.name
                    }}</v-chip>
                </div>
            </template>

            <template v-slot:item="{ item, attrs, on }">
                <v-list-item v-bind="attrs" v-on="on">
                    <v-list-item-action>
                        <v-radio-group v-model="currentTierSelected">
                            <v-radio color="primary" :value="item.tier"></v-radio>
                        </v-radio-group>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title :id="attrs['aria-labelledby']">
                            <span v-if="item.tier === 0">{{ item.name }}</span>
                            <v-chip
                                v-else
                                small
                                label
                                :color="getTierColor(item.tier)"
                                class="white--text"
                                >{{ item.name }}</v-chip
                            >
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </template>
        </v-select>
    </v-row>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
    name: 'TierPicker',
    data() {
        return {
            currentTierSelected: 0,
        }
    },
    computed: {
        ...mapState('reports', [
            'tiers'
        ]),
        tiersObject() {
            return [
                {
                    id: 1,
                    tier: 0,
                    name: this.$t('reports.generalFilter.defaultValues.all')
                },
                {
                    id: 2,
                    tier: 1,
                    name: `${this.$t('reports.utils.level')} 1`
                },
                {
                    id: 3,
                    tier: 2,
                    name: `${this.$t('reports.utils.level')} 2`
                },
                {
                    id: 4,
                    tier: 3,
                    name: `${this.$t('reports.utils.level')} 3`
                },
            ]
        }
    },
    methods: {
        ...mapActions('reports', [
            'setTiers',
            'setTierSelected',
        ]),
        handleTiersOptions() {
            const tiers = this.tiersObject
            this.setTiers(tiers)
            this.setTierSelected(this.tiers.find(tier => tier.tier === 0).tier)
        },
        getTierColor(tierID) {
            switch (tierID) {
                case 1:
                    return 'green darken-2' 
                case 2:
                    return 'amber accent-3' 
                case 3:
                    return 'red darken-2' 
                default:
                    return 'green darken-3' 
            }
        },
    },
    watch: {
        currentTierSelected(ordinalTier) {
            this.setTierSelected(this.tiers.find(tier => tier.tier === ordinalTier))
        },
    },
    async beforeMount() {
        await this.handleTiersOptions()
    }
}
</script>
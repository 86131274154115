<template>
    <v-row class="d-flex flex-column px-5">
        <span
            class="grey--text lighten-3 body-2 font-weight-medium"
            data-cy="generalFilter-courseTitle-span"
        >
            {{ $t('reports.generalFilter.filters.courses') }}
        </span>
        <!-- Course Select Dropdown -->
        <v-select
            outlined
            :items="courses"
            v-model="currentCourseID"
            item-value="id"
            item-text="name"
            color="primary"
            prepend-inner-icon="mdi-folder"
            :hide-details="true"
            no-data-text="There is no courses"
            :error="false"
            small-chips
            dense
            hidden
            data-cy="generalFilter-courseSelect-select"
        >
            <template v-slot:prepend-item>
                <v-col class="pa-5">
                    <span
                        class="body-2 font-weight-bold"
                        data-cy="generalFilter-courseDetailTitle-span"
                    >
                        {{ $t('reports.generalFilter.labels.filterByCourses') }}
                    </span>
                </v-col>
            </template>

            <template v-slot:selection="{ item }">
                <v-chip color="deep-purple lighten-5" class="w-100" v-if="item.id === 0">
                    <span class="deep-purple--text darken-3 body-2 font-weight-medium">{{
                        item.name
                    }}</span>
                </v-chip>
                <v-chip color="primary" label small class="w-100" v-else>
                    <span class="white--text body-2 font-weight-medium">{{ item.name }}</span>
                </v-chip>
            </template>

            <template v-slot:item="{ item, attrs, on }">
                <v-list-item v-bind="attrs" v-on="on">
                    <v-list-item-action>
                        <v-radio-group v-model="currentCourseID">
                            <v-radio color="primary" :value="item.id"></v-radio>
                        </v-radio-group>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title :id="attrs['aria-labelledby']">
                            <span>{{ item.name }}</span>
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </template>
        </v-select>
    </v-row>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
export default {
    name: 'CoursePicker',
    data() {
        return {
            currentCourseID: 0
        }
    },
    computed: {
        ...mapState('reports', [
            'courses',
            'courseSelected',
            'studentsPerCourse',
            'dateOptionSelected',
            'filteredStudents',
        ]),
        ...mapState('userData', {
            teacherCourses: 'courses',
        }),
        ...mapGetters('reports', [
            'studentsFromCourse'
        ])
    },
    methods: {
      ...mapActions('reports', [
        'setCurrentFilteredStudents',
        'setCourses',
        'setCourseSelected',
        'selectCourse'
      ]),
      async handleTeacherCourses() {
            if(!this.teacherCourses.length) return
            await this.setCourses(this.teacherCourses)
            this.setCourseSelected(this.teacherCourses[0])
            this.currentCourseID = this.courseSelected.id
        },
    },
    watch: {
        currentCourseID(newCourse) {
            this.selectCourse(newCourse)
            this.setCurrentFilteredStudents(this.studentsFromCourse(newCourse))
        },
    },
    async beforeMount() {
        await this.handleTeacherCourses()
    }
}
</script>
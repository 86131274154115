import { Student } from '@/data/dto/student'
import { ChallengeInformationData } from '@/data/reports/responses'
import { AmountOfQuestionTable, StudentsChallengePerformanceTable } from '@/data/reports/tables'
import { SKILLS_COLORS } from '@/data/skillsConstants'
import Vue from 'vue'

const mapChallengesInformation = Vue.extend({
    methods: {
        getOrdinalTier(tierGroup: string) {
            switch (tierGroup) {
                case 'TIER_GROUP_1':
                    return 1
                case 'TIER_GROUP_2':
                    return 2
                case 'TIER_GROUP_3':
                    return 3
                default:
                    return 0
            }
        },
        getTotalChallenges(challengeInformation: ChallengeInformationData) {
            const refChallenges = challengeInformation.refAndValChallengeSkillData?.totalAnswers || 0
            const locateChallenges = challengeInformation.locateChallengeSkillData?.totalAnswers || 0
            const interpretationChallenges = challengeInformation.interpretationChallengeSkillData?.totalAnswers || 0

            return refChallenges + locateChallenges + interpretationChallenges
        },
        mapChallengeInformation(student: Student, challengeInformation: ChallengeInformationData): StudentsChallengePerformanceTable {
            return {
               student: {
                id: student.id,
                name: `${student.firstname} ${student.lastname}`
               },
               locate: challengeInformation.locateChallengeSkillData.totalAnswers ? {
                average: Math.round(challengeInformation.locateChallengeSkillData.average),
                total: challengeInformation.locateChallengeSkillData.totalAnswers, 
                corrects: challengeInformation.locateChallengeSkillData.totalCorrectAnswers, 
               } : null,
               integrate: challengeInformation.interpretationChallengeSkillData.totalAnswers ? {
                average: Math.round(challengeInformation.interpretationChallengeSkillData.average),
                total: challengeInformation.interpretationChallengeSkillData.totalAnswers, 
                corrects: challengeInformation.interpretationChallengeSkillData.totalCorrectAnswers, 
               } : null,
               reflect: challengeInformation.refAndValChallengeSkillData.totalAnswers ? {
                average: Math.round(challengeInformation.refAndValChallengeSkillData.average),
                total: challengeInformation.refAndValChallengeSkillData.totalAnswers, 
                corrects: challengeInformation.refAndValChallengeSkillData.totalCorrectAnswers, 
               } : null,
               readingComprehension: challengeInformation.challengesReadingComprehensionAverage ? Math.round(challengeInformation.challengesReadingComprehensionAverage) : null,
               challengeAmount: challengeInformation.totalChallenges
            }
        },
        mapNullChallengeInformation(student: Student): StudentsChallengePerformanceTable {
            return {
                student: {
                 id: student.id,
                 name: `${student.firstname} ${student.lastname}`
                },
                locate: null,
                integrate: null,
                reflect: null,
                readingComprehension: null,
                challengeAmount: 0
             }
        },
        getAllChallengesPerStudent(students: Student[], challengesInformationData: ChallengeInformationData[], tier?: number): StudentsChallengePerformanceTable[] {
            const currChallengesInformation: StudentsChallengePerformanceTable[] = []

            if (tier) {
                challengesInformationData = challengesInformationData.filter(
                    (challengeData) =>
                        this.getOrdinalTier(challengeData.studentInfo?.studentTier) === tier
                )

                challengesInformationData.forEach(challengeData => {
                    const currentStudent = students.find(student => challengeData.studentInfo.id === student.id)
                    if(!currentStudent) return
                    currChallengesInformation.push(this.mapChallengeInformation(currentStudent, challengeData))
                })
                return currChallengesInformation
            }

            students.forEach((student) => {
                if (student.id === 0) {
                    return
                }
                const currStudentChallengeData = challengesInformationData.find(
                    (challengeData) => challengeData.studentInfo.id === student.id
                )

                if (!currStudentChallengeData)
                    currChallengesInformation.push(this.mapNullChallengeInformation(student))
                else
                    currChallengesInformation.push(
                        this.mapChallengeInformation(
                            student,
                            currStudentChallengeData
                        )
                    )
            })
            return currChallengesInformation
        },
        getStudentChallengesData(student: Student, challengesInformation: ChallengeInformationData[]): StudentsChallengePerformanceTable[] {
            const currChallengesInformation: StudentsChallengePerformanceTable[] = []
            const currChallengeData = challengesInformation.find(
                (challengeData) => challengeData.studentInfo.id === student.id
            )
            currChallengeData
                ? currChallengesInformation.push(
                      this.mapChallengeInformation(student, currChallengeData)
                  )
                : currChallengesInformation.push(this.mapNullChallengeInformation(student))

            return currChallengesInformation
        },
        getAmountOfChallengesQuestions(challengesInformation: ChallengeInformationData[], tier?: number):AmountOfQuestionTable[] {
            if(tier) {
                challengesInformation = challengesInformation.filter(challengeData => this.getOrdinalTier(challengeData.studentInfo.studentTier) === tier)
            }

            const challengesDataLength = challengesInformation.length

            const locateSkillData: AmountOfQuestionTable = {
                skill: 'locate',
                corrects: challengesDataLength ? challengesInformation.map(challengeData => challengeData.locateChallengeSkillData.totalCorrectAnswers).reduce((prev, next) => prev + next) : 0,
                incorrects: challengesDataLength ? challengesInformation.map(challengeData => challengeData.locateChallengeSkillData.totalIncorrectAnswers).reduce((prev, next) => prev + next) : 0,
                total: challengesDataLength ? challengesInformation.map(challengeData => challengeData.locateChallengeSkillData.totalAnswers).reduce((prev, next) => prev + next) : 0,
            }
            const integrateSkillData: AmountOfQuestionTable = {
                skill: 'integrate',
                corrects: challengesDataLength ? challengesInformation.map(challengeData => challengeData.interpretationChallengeSkillData.totalCorrectAnswers).reduce((prev, next) => prev + next) : 0,
                incorrects: challengesDataLength ? challengesInformation.map(challengeData => challengeData.interpretationChallengeSkillData.totalIncorrectAnswers).reduce((prev, next) => prev + next) : 0,
                total: challengesDataLength ? challengesInformation.map(challengeData => challengeData.interpretationChallengeSkillData.totalAnswers).reduce((prev, next) => prev + next) : 0,
            }
            const reflectSkillData: AmountOfQuestionTable = {
                skill: 'reflect',
                corrects: challengesDataLength ? challengesInformation.map(challengeData => challengeData.refAndValChallengeSkillData.totalCorrectAnswers).reduce((prev, next) => prev + next) : 0,
                incorrects: challengesDataLength ? challengesInformation.map(challengeData => challengeData.refAndValChallengeSkillData.totalIncorrectAnswers).reduce((prev, next) => prev + next) : 0,
                total: challengesDataLength ? challengesInformation.map(challengeData => challengeData.refAndValChallengeSkillData.totalAnswers).reduce((prev, next) => prev + next) : 0,
            }

            return [locateSkillData, integrateSkillData, reflectSkillData]
        },
        mapNullChallengeAmountOfQuestion(): AmountOfQuestionTable[] {
            return [
                {
                    skill: 'locate',
                    corrects: 0,
                    incorrects: 0,
                    total: 0
                },
                {
                    skill: 'integrate',
                    corrects: 0,
                    incorrects: 0,
                    total: 0
                },
                {
                    skill: 'reflect',
                    corrects: 0,
                    incorrects: 0,
                    total: 0
                },
            ]
        },
        getStudentAmountOfChallengesQuestions(student: Student, challengesInformation: ChallengeInformationData[]):AmountOfQuestionTable[] {

            const currChallengeData = challengesInformation.find(challengeData => challengeData.studentInfo.id === student.id)

            if(!currChallengeData) {
                return this.mapNullChallengeAmountOfQuestion()
            }

            const locateSkillData: AmountOfQuestionTable = {
                skill: 'locate',
                corrects: currChallengeData.locateChallengeSkillData?.totalCorrectAnswers || 0,
                incorrects: currChallengeData.locateChallengeSkillData?.totalIncorrectAnswers || 0,
                total: currChallengeData.locateChallengeSkillData?.totalAnswers || 0,
            }
            const integrateSkillData: AmountOfQuestionTable = {
                skill: 'integrate',
                corrects: currChallengeData.interpretationChallengeSkillData?.totalCorrectAnswers || 0,
                incorrects: currChallengeData.interpretationChallengeSkillData?.totalIncorrectAnswers || 0,
                total: currChallengeData.interpretationChallengeSkillData?.totalAnswers || 0,
            }
            const reflectSkillData: AmountOfQuestionTable = {
                skill: 'reflect',
                corrects: currChallengeData.refAndValChallengeSkillData?.totalCorrectAnswers || 0,
                incorrects: currChallengeData.refAndValChallengeSkillData?.totalIncorrectAnswers || 0,
                total: currChallengeData.refAndValChallengeSkillData?.totalAnswers || 0,
            }

            return [locateSkillData, integrateSkillData, reflectSkillData]
        },
        mapNullChallengeResultGraph() {
            return [
                {
                    value: 0,
                    itemStyle: { color: SKILLS_COLORS.locate },
                },
                {
                    value: 0,
                    itemStyle: { color: SKILLS_COLORS.integrate },
                },
                {
                    value: 0,
                    itemStyle: { color: SKILLS_COLORS.reflect },
                },
                {
                    value: 0,
                    itemStyle: { color: SKILLS_COLORS.readingComprehension },
                },
            ]
        },
        mapStudentChallengeResultGraph(student: Student, challengeData: ChallengeInformationData[]) {
            const challengeDataLength = challengeData.length

            if(!challengeDataLength) return this.mapNullChallengeResultGraph()
            const currStudentChallengeData = challengeData.find(data => data.studentInfo.id === student.id)
            if(!currStudentChallengeData) return this.mapNullChallengeResultGraph()

            return [
                {
                    value: currStudentChallengeData.locateChallengeSkillData.average ? currStudentChallengeData.locateChallengeSkillData.average.toFixed() : 0,
                    itemStyle: { color: SKILLS_COLORS.locate },
                },
                {
                    value: currStudentChallengeData.interpretationChallengeSkillData.average ? currStudentChallengeData.interpretationChallengeSkillData.average.toFixed() : 0,
                    itemStyle: { color: SKILLS_COLORS.integrate },
                },
                {
                    value: currStudentChallengeData.refAndValChallengeSkillData.average ? currStudentChallengeData.refAndValChallengeSkillData.average.toFixed() : 0,
                    itemStyle: { color: SKILLS_COLORS.reflect },
                },
                {
                    value: currStudentChallengeData.challengesReadingComprehensionAverage ? currStudentChallengeData.challengesReadingComprehensionAverage.toFixed() : 0,
                    itemStyle: { color: SKILLS_COLORS.readingComprehension },
                },
            ]
         },
         getReadingComprehensionAverage(tableData: AmountOfQuestionTable[]): number {
            const locateData = tableData[0]
            const interpretation = tableData[1]
            const reflect = tableData[2]
            const totalQuestions = locateData.total + interpretation.total + reflect.total

            const locateAverage = (locateData.corrects / locateData.total) * ( locateData.total * 100 / totalQuestions)
            const interpretationAverage = (interpretation.corrects / interpretation.total) * ( interpretation.total * 100 / totalQuestions)
            const reflectAverage = (reflect.corrects / reflect.total) * ( reflect.total * 100 / totalQuestions)

            return Number((locateAverage + interpretationAverage + reflectAverage).toFixed())
        },
        mapChallengeResultsGraph(challengeData: ChallengeInformationData[], tier?: number) {

            if(tier) {
                challengeData = challengeData.filter(data => this.getOrdinalTier(data.studentInfo.studentTier) === tier)
            }

            const challengeDataLength = challengeData.length

            if(!challengeDataLength) return this.mapNullChallengeResultGraph()

            const tableData = this.getAmountOfChallengesQuestions(challengeData)

            return [
                {
                    value: tableData[0].total ? (tableData[0].corrects / tableData[0].total * 100).toFixed() : 0,
                    itemStyle: { color: SKILLS_COLORS.locate },
                },
                {
                    value: tableData[1].total ? (tableData[1].corrects / tableData[1].total * 100).toFixed() : 0,
                    itemStyle: { color: SKILLS_COLORS.integrate },
                },
                {
                    value: tableData[2].total ? (tableData[2].corrects / tableData[2].total * 100).toFixed() : 0,
                    itemStyle: { color: SKILLS_COLORS.reflect },
                },
                {
                    value: this.getReadingComprehensionAverage(tableData),
                    itemStyle: { color: SKILLS_COLORS.readingComprehension },
                },
            ]
        }
    }
})

export default mapChallengesInformation

import { Student } from '@/data/dto/student'
import { AssignedBookData, AssignedYourselfData } from '@/data/reports/responses'
import { AmountOfQuestionTable, AssignedBookTable, AssignedYourselfTableData } from '@/data/reports/tables'
import { SKILLS_COLORS } from '@/data/skillsConstants'
import Vue from 'vue'

const mapBooksInformationData = Vue.extend({
  methods: {
    getOrdinalTier(tierGroup: string) {
      switch (tierGroup) {
        case 'TIER_GROUP_1':
          return 1
        case 'TIER_GROUP_2':
          return 2
        case 'TIER_GROUP_3':
          return 3
        default:
          return 0
      }
    },
    getBookInformation(book: AssignedBookData): AssignedBookTable {
      return {
        assignment: {
          id: book.assignment_id,
          bookTitle: book.booktitle
        },
        bookId: book.bookId,
        bookTitle: book.booktitle + '',
        lexileMeasure: book.bookAssignedMetricInfo.lexile,
        bookProgress: Math.round(book.bookAssignedMetricInfo.progress),
        readingTime: book.bookAssignedMetricInfo.lectureTime ?
          this.formatReadingTime(Number(book.bookAssignedMetricInfo.lectureTime))
          : null,
        locate: book.bookAssignedMetricInfo.locateSkillData.totalAnswers ? {
          average: Math.round(book.bookAssignedMetricInfo.locateSkillData.average),
          corrects:
              book.bookAssignedMetricInfo.locateSkillData.totalCorrectAnswers,
          incorrects:
              book.bookAssignedMetricInfo.locateSkillData.totalIncorrectAnswers,
          total: book.bookAssignedMetricInfo.locateSkillData.totalAnswers,
        } : null,
        integrate: book.bookAssignedMetricInfo.interpretationSkillData.totalAnswers ? {
          average: Math.round(book.bookAssignedMetricInfo.interpretationSkillData.average),
          corrects:
              book.bookAssignedMetricInfo.interpretationSkillData.totalCorrectAnswers,
          incorrects:
              book.bookAssignedMetricInfo.interpretationSkillData.totalIncorrectAnswers,
          total: book.bookAssignedMetricInfo.interpretationSkillData.totalAnswers,
        } : null,
        reflect: book.bookAssignedMetricInfo.refAndValSkillData.totalAnswers ? {
          average: Math.round(book.bookAssignedMetricInfo.refAndValSkillData.average),
          corrects:
              book.bookAssignedMetricInfo.refAndValSkillData.totalCorrectAnswers,
          incorrects:
              book.bookAssignedMetricInfo.refAndValSkillData.totalIncorrectAnswers,
          total: book.bookAssignedMetricInfo.refAndValSkillData.totalAnswers,
        } : null,
        readingComprehension: Math.round(book.bookAssignedMetricInfo.readingComprehensionAverage) || null,
      }
    },
    // ----------------------------
    // Assigned books data methods
    // ----------------------------
    getAssignedBooks(assignedBooks: AssignedBookData[]): AssignedBookTable[] {
      const books: AssignedBookTable[] = []
      assignedBooks.forEach((assignedBook) =>
        books.push(this.getBookInformation(assignedBook))
      )
      return books
    },
    // ----------------------------
    // Books Amount of Question methods
    // ----------------------------
    getBooksAmountOfQuestions(assignedBooks: AssignedBookData[], student?: Student): AmountOfQuestionTable[] {

      const assignedBooksLength = assignedBooks.length

      const locateSkillData: AmountOfQuestionTable = {
        skill: 'locate',
        corrects: assignedBooksLength ? assignedBooks.map(assignedBook => assignedBook.bookAssignedMetricInfo.locateSkillData.totalCorrectAnswers).reduce((prev, next) => prev + next) : 0,
        incorrects: assignedBooksLength ? assignedBooks.map(assignedBook => assignedBook.bookAssignedMetricInfo.locateSkillData.totalIncorrectAnswers).reduce((prev, next) => prev + next) : 0,
        total: assignedBooksLength ? assignedBooks.map(assignedBook => assignedBook.bookAssignedMetricInfo.locateSkillData.totalAnswers).reduce((prev, next) => prev + next) : 0,
      }
      const integrateSkillData: AmountOfQuestionTable = {
        skill: 'integrate',
        corrects: assignedBooksLength ? assignedBooks.map(assignedBook => assignedBook.bookAssignedMetricInfo.interpretationSkillData.totalCorrectAnswers).reduce((prev, next) => prev + next) : 0,
        incorrects: assignedBooksLength ? assignedBooks.map(assignedBook => assignedBook.bookAssignedMetricInfo.interpretationSkillData.totalIncorrectAnswers).reduce((prev, next) => prev + next) : 0,
        total: assignedBooksLength ? assignedBooks.map(assignedBook => assignedBook.bookAssignedMetricInfo.interpretationSkillData.totalAnswers).reduce((prev, next) => prev + next) : 0,
      }
      const reflectSkillData: AmountOfQuestionTable = {
        skill: 'reflect',
        corrects: assignedBooksLength ? assignedBooks.map(assignedBook => assignedBook.bookAssignedMetricInfo.refAndValSkillData.totalCorrectAnswers).reduce((prev, next) => prev + next) : 0,
        incorrects: assignedBooksLength ? assignedBooks.map(assignedBook => assignedBook.bookAssignedMetricInfo.refAndValSkillData.totalIncorrectAnswers).reduce((prev, next) => prev + next) : 0,
        total: assignedBooksLength ? assignedBooks.map(assignedBook => assignedBook.bookAssignedMetricInfo.refAndValSkillData.totalAnswers).reduce((prev, next) => prev + next) : 0,
      }
      return [locateSkillData, integrateSkillData, reflectSkillData]
    },
    // -------------------------------------
    // Assigned yourself books data methods
    // -------------------------------------
    getNullAssignedYourselfBookDataStudent(student: Student): AssignedYourselfTableData {
      return {
        student: {
          name: `${student.firstname} ${student.lastname}`,
          id: student.id,
        },
        booksAmount: 0,
        readingTime: '0',
      }
    },
    getAssignedYourselfBookDataStudent(
      student: Student,
      assignedYourselfData: AssignedYourselfData
    ): AssignedYourselfTableData {
      return {
        student: {
          name: `${student.firstname} ${student.lastname}`,
          id: student.id,
        },
        booksAmount: assignedYourselfData.quantityOfBooks,
        readingTime: assignedYourselfData.lectureTime ?
          this.formatReadingTime(Number(assignedYourselfData.lectureTime))
          : '0',
      }
    },
    getAllAssignedYourselfBooks(
      students: Student[],
      assignedYourselfData: AssignedYourselfData[],
      tierSelected?: number
    ): AssignedYourselfTableData[] {
      const currAssignedBooks: AssignedYourselfTableData[] = []

      if (tierSelected) {
        assignedYourselfData = assignedYourselfData.filter(
          (assignedData) =>
            this.getOrdinalTier(assignedData.studentInfo?.studentTier) === tierSelected
        )

        assignedYourselfData.forEach(assignedData => {
          const currentStudent = students.find(student => assignedData.studentInfo.id === student.id)
          if(!currentStudent) return
          currAssignedBooks.push(this.getAssignedYourselfBookDataStudent(currentStudent, assignedData))
        })
        return currAssignedBooks
      }

      students.forEach((student) => {
        if (student.id === 0) {
          return
        }
        const currStudentAssignedBookData = assignedYourselfData.find(
          (assignedData) => assignedData.studentInfo.id === student.id
        )

        if (!currStudentAssignedBookData)
          currAssignedBooks.push(this.getNullAssignedYourselfBookDataStudent(student))
        else
          currAssignedBooks.push(
            this.getAssignedYourselfBookDataStudent(
              student,
              currStudentAssignedBookData
            )
          )
      })

      return currAssignedBooks
    },
    getStudentAssignedYourselfBooksData(
        student: Student,
        assignedYourselfData: AssignedYourselfData[]
    ): AssignedYourselfTableData[] {
        const currAssignedBooks: AssignedYourselfTableData[] = []
        const currAssignedBook = assignedYourselfData.find(
          (bookData) => bookData.studentInfo.id === student.id
        )
        currAssignedBook
          ? currAssignedBooks.push(
              this.getAssignedYourselfBookDataStudent(student, currAssignedBook)
            )
          : currAssignedBooks.push(this.getNullAssignedYourselfBookDataStudent(student))

      return currAssignedBooks
    },
    getAverage(data: number[]): number {
      const totalSum = data.reduce((prev, next) => prev + next)
      return Number((totalSum / data.length).toFixed())
    },
    getSkillAverage(correctsAnswers: number[], totalQuestions: number[]): number {
      const totalSumCorrects = correctsAnswers.reduce((prev, next) => prev + next)
      const totalSumQuestions = totalQuestions.reduce((prev, next) => prev + next)
      return Number(((totalSumCorrects / totalSumQuestions) * 100).toFixed())
    },
    getReadingComprehensionAverage(tableData: AmountOfQuestionTable[]): number {
      const locateData = tableData[0]
      const interpretation = tableData[1]
      const reflect = tableData[2]
      const totalQuestions = locateData.total + interpretation.total + reflect.total

      const locateAverage = (locateData.corrects / locateData.total) * ( locateData.total * 100 / totalQuestions)
      const interpretationAverage = (interpretation.corrects / interpretation.total) * ( interpretation.total * 100 / totalQuestions)
      const reflectAverage = (reflect.corrects / reflect.total) * ( reflect.total * 100 / totalQuestions)

      return Number((locateAverage + interpretationAverage + reflectAverage).toFixed())
    },
    getBookResultsGraph(booksInformation: AssignedBookData[]) {
      const tableData = this.getBooksAmountOfQuestions(booksInformation)

      return [
        {
          value: tableData[0].total ? (tableData[0].corrects / tableData[0].total * 100).toFixed() : 0,
          itemStyle: { color: SKILLS_COLORS.locate },
        },
        {
          value: tableData[1].total ? (tableData[1].corrects / tableData[1].total * 100).toFixed() : 0,
          itemStyle: { color: SKILLS_COLORS.integrate },
        },
        {
          value: tableData[2].total ? (tableData[2].corrects / tableData[2].total * 100).toFixed() : 0,
          itemStyle: { color: SKILLS_COLORS.reflect },
        },
        {
          value: this.getReadingComprehensionAverage(tableData) || 0,
          itemStyle: { color: SKILLS_COLORS.readingComprehension },
        },
      ]
    },
    formatReadingTime(readingTime: number): string {
      const HOUR_TIME = 1
      return readingTime >= HOUR_TIME ? `${readingTime.toFixed(1)} h` : this.convertHoursToMinutes(readingTime)
    },
    convertHoursToMinutes(hours: number): string {
      const minutes = hours * 60
      return `${minutes.toFixed(1)} min`;
    }
  },
})

export default mapBooksInformationData

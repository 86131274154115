<template>
    <v-container class="d-flex align-center justify-center bar-chart__container">
        <vue-chart :option="graphOption" class="bar-chart"></vue-chart>
    </v-container>
</template>

<script>
import ECharts from 'vue-echarts'
import { use } from 'echarts/core'

import { CanvasRenderer } from 'echarts/renderers'
import { BarChart } from 'echarts/charts'
import { GridComponent, TooltipComponent } from 'echarts/components'

use([CanvasRenderer, BarChart, GridComponent, TooltipComponent])

export default {
    components: {
        'vue-chart': ECharts,
    },
    props: {
        bars: { required: true },
        data: { required: true },
    },
    computed: {
        graphData() { return this.data  }
    },
    data() {
        return {
            graphOption: {
                textStyle: {
                    fontFamily: 'Poppins',
                },
                xAxis: {
                    type: 'category',
                    data: this.bars,
                    axisLabel: {
                        fontSize: 7,
                        hideOverlap: false,
                        showMinLabel: true,
                        showMaxLabel: true
                    },
                },
                yAxis: [
                    {
                        type: 'value',
                        min: 0,
                        max: 100,
                        axisLabel : {
                            formatter: '{value}%',
                            fontSize: 12,
                        },
                    }
                ],
                series: [
                    {
                        label: {
                            show: true,
                            position: 'top',
                            formatter: '{c}%',
                            color: 'inherit',
                            fontWeight: 'bold',
                            fontSize: 12,
                        },
                        data: this.data,
                        barMaxWidth: 60,
                        type: 'bar',
                    },
                ],
                legend: {
                    data: this.graphData,
                },
                media: [
                    {
                        query: {
                            maxWidth: 500,
                        },
                        option: {
                            textStyle: {
                                fontFamily: 'Poppins',
                            },
                            xAxis: {
                                axisLabel: {
                                    fontSize: 9,
                                },
                            },
                            yAxis: [
                                {
                                    axisLabel: {
                                        fontSize: 8,
                                    },
                                }
                            ],
                            series: [
                                {
                                    label: {
                                        fontSize: 8,
                                        barMaxWidth: 40,
                                    },
                                },
                            ],
                        },
                    },
                    {
                        query: {
                            maxWidth: 540,
                        },
                        option: {
                            textStyle: {
                                fontFamily: 'Poppins',
                            },
                            xAxis: {
                                axisLabel: {
                                    fontSize: 12,
                                },
                            },
                            yAxis: [
                                {
                                    axisLabel: {
                                        fontSize: 9,
                                    },
                                }
                            ],
                            series: [
                                {
                                    label: {
                                        fontSize: 12,
                                        barMaxWidth: 40,
                                    },
                                },
                            ],
                        },
                    },
                ]
            },
        }
    },
}
</script>

<style lang="css" scoped>
.bar-chart__container {
    position: relative;
    width: 100%;
    height: 100%;
}
.bar-chart {
    width: 330px !important;
    height: 300px !important;
}

@media (min-width: 900px) {
    .bar-chart {
        width: 500px !important;
        height: 330px !important;
    }
}

@media (min-width: 1024px) {
    .bar-chart {
        width: 540px !important;
        height: 330px !important;
    }
}
</style>

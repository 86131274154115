<template>
    <div class="w-100">
        <v-row cols="12">
            <v-col cols="6">
                <Card
                    data-cy="reportsChallenge-resultsGraph-card"
                    :title="$t('reports.dashboard.cards.challengeResults')"
                    :tooltipMsg="
                        $t('reports.dashboard.tooltips.challengesInformation.challengesResults')
                    "
                >
                    <template v-slot:content>
                        <BarChart
                            v-if="renderGraph"
                            :bars="graphData.bars"
                            :data="generateGraphData"
                        />
                        <span v-else>{{ $t('general.loading') }}</span>
                    </template>
                </Card>
            </v-col>
            <v-col cols="6" class="pb-7">
                <Card
                    data-cy="reportsChallenge-questionsTable-card"
                    :title="$t('reports.dashboard.cards.questionsAmount')"
                    :tooltipMsg="
                        $t('reports.dashboard.tooltips.challengesInformation.questionsAmount')
                    "
                    fullHeight
                >
                    <template v-slot:content>
                        <Table
                            :headers="generateHeadersForNOfQTable"
                            :items="generateItemsForNOfQTable"
                            withQuestionsAmount
                            :loading="isLoadingData"
                        />
                    </template>
                </Card>
            </v-col>
        </v-row>
        <Card
            data-cy="reportsChallenge-performanceTable-card"
            :title="$t('reports.dashboard.cards.studentsPerformance')"
            :tooltipMsg="$t('reports.dashboard.tooltips.challengesInformation.studentsPerformance')"
        >
            <template v-slot:content>
                <Table
                    :headers="generateHeadersForABTable"
                    :items="generateItemsForABTable"
                    :studentNameCallback="setSelectedStudent"
                    withStudent
                    withPerformanceSkills
                    :loading="isLoadingData"
                />
            </template>
        </Card>
    </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import mapChallengesInformation from '@/mixins/reports/mapChallengesInformation'
import Card from '../shared/card/index.vue'
import Table from '../shared/table/index.vue'
import BarChart from '../shared/graphs/barChart/index.vue'

export default {
    name: 'Challenges',
    components: { Card, Table, BarChart },
    mixins: [mapChallengesInformation],
    data() {
        return {
            graphData: {
                bars: [
                    this.$t('reports.skills.abbrevationName.locate'),
                    this.$t('reports.skills.abbrevationName.integrate'),
                    this.$t('reports.skills.abbrevationName.reflect'),
                    this.$t('reports.skills.abbrevationName.readingComprehension'),
                ],
                data: [],
            },
            currentChallengesInformation: [],
            currentChallengesPerformance: [],
            currentChallengesNumberOfQuestions: [],
            currentGraphData: [],
            shouldGraphRender: false,
        }
    },
    computed: {
        ...mapState('reports', [
            'courseSelected',
            'studentSelected',
            'studentsPerCourse',
            'tierSelected',
            'challengesInformation',
            'dateOptionSelected',
            'dates',
        ]),
        ...mapGetters('reports', [
            'studentsFromCourse',
            'getCourseBooksInformation',
            'isLoadingData',
        ]),
        generateHeadersForABTable() {
            return [
                {
                    text: this.$t('reports.tableHeaders.student'),
                    align: 'start',
                    sortable: false,
                    value: 'student',
                },
                {
                    text: this.$t('reports.skills.fullName.locate'),
                    align: 'center',
                    sortable: false,
                    value: 'locate',
                },
                {
                    text: this.$t('reports.skills.fullName.integrate'),
                    align: 'center',
                    sortable: false,
                    value: 'integrate',
                },
                {
                    text: this.$t('reports.skills.fullName.reflect'),
                    align: 'center',
                    sortable: false,
                    value: 'reflect',
                },
                {
                    text: this.$t('reports.dashboard.cards.readingComprehension'),
                    align: 'center',
                    sortable: true,
                    value: 'readingComprehension',
                },
                {
                    text: this.$t('reports.tableHeaders.challengesAmount'),
                    align: 'center',
                    sortable: true,
                    value: 'challengeAmount',
                },
            ]
        },
        generateItemsForABTable() {
            return this.currentChallengesPerformance
        },
        generateHeadersForNOfQTable() {
            return [
                {
                    text: this.$t('reports.tableHeaders.skill'),
                    align: 'start',
                    sortable: false,
                    value: 'skill',
                },
                {
                    text: this.$t('reports.tableHeaders.corrects'),
                    align: 'center',
                    sortable: false,
                    value: 'corrects',
                },
                {
                    text: this.$t('reports.tableHeaders.incorrects'),
                    align: 'center',
                    sortable: false,
                    value: 'incorrects',
                },
                {
                    text: this.$t('reports.tableHeaders.total'),
                    align: 'center',
                    sortable: false,
                    value: 'total',
                },
            ]
        },
        generateItemsForNOfQTable() {
            return this.currentChallengesNumberOfQuestions
        },
        generateGraphData() {
            return this.currentGraphData
        },
        renderGraph() {
            return !this.isLoadingData && this.shouldGraphRender
        },
    },
    methods: {
        ...mapActions('reports', ['getChallengesInformationFromCourse', 'setSelectedStudent']),
        updateChallengePerformance() {
            if (this.studentSelected) {
                this.currentChallengesPerformance = this.getStudentChallengesData(
                    this.studentSelected,
                    this.currentChallengesInformation || []
                )
            } else {
                this.currentChallengesPerformance =
                    this.tierSelected && this.tierSelected.tier != 0
                        ? this.getAllChallengesPerStudent(
                              this.studentsFromCourse(this.courseSelected.id),
                              this.currentChallengesInformation || [],
                              this.tierSelected.tier
                          )
                        : this.getAllChallengesPerStudent(
                              this.studentsFromCourse(this.courseSelected.id),
                              this.currentChallengesInformation || []
                          )
            }
        },
        updateChallengesNumberOfQuestions() {
            if (this.studentSelected) {
                this.currentChallengesNumberOfQuestions = this.getStudentAmountOfChallengesQuestions(
                    this.studentSelected,
                    this.currentChallengesInformation || []
                )
            } else {
                this.currentChallengesNumberOfQuestions = this.getAmountOfChallengesQuestions(
                    this.currentChallengesInformation || [],
                    this.tierSelected.tier
                )
            }
        },
        updateChallengesGraph() {
            this.shouldGraphRender = false
            if (this.studentSelected) {
                this.currentGraphData = this.mapStudentChallengeResultGraph(
                    this.studentSelected,
                    this.currentChallengesInformation
                )
            } else {
                this.currentGraphData = this.tierSelected
                    ? this.mapChallengeResultsGraph(
                          this.currentChallengesInformation,
                          this.tierSelected.tier
                      )
                    : this.mapChallengeResultsGraph(this.currentChallengesInformation)
            }

            setTimeout(() => (this.shouldGraphRender = true), 0)
        },
        async updateChallengesInformation() {
            if (!this.dates || !this.dates?.endDate || !this.dates?.initialDate) return

            const challengesInformation = await this.getChallengesInformationFromCourse({
                courseID: this.courseSelected.id,
                dateRangeOption: this.dateOptionSelected,
            })

            this.currentChallengesInformation = challengesInformation
        },
        async setDefaultData() {
            await this.updateChallengesInformation()

            this.updateChallengePerformance()
            this.updateChallengesNumberOfQuestions()
            this.updateChallengesGraph()
        },
    },
    watch: {
        async courseSelected() {
            await this.setDefaultData()
        },
        tierSelected() {
            this.updateChallengePerformance()
            this.updateChallengesNumberOfQuestions()
            this.updateChallengesGraph()
        },
        studentSelected() {
            this.updateChallengePerformance()
            this.updateChallengesNumberOfQuestions()
            this.updateChallengesGraph()
        },
        async dateOptionSelected() {
            if (!this.courseSelected) return
            await this.updateChallengesInformation()

            this.updateChallengePerformance()
            this.updateChallengesNumberOfQuestions()
            this.updateChallengesGraph()
        },
        'dates.initialDate': async function () {
            if (this.dateOptionSelected === 0) await this.setDefaultData()
        },
        'dates.endDate': async function () {
            if (this.dateOptionSelected === 0) await this.setDefaultData()
        },
    },
}
</script>

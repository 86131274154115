<template>
    <div class="mt-4 w-100">
        <vue-headful :title="$t('reports.dashboard.title')" description="title" />
        <h5 class="text-h4 my-6" style="font-weight: bolder">
            {{ $t('reports.dashboard.title') }}
        </h5>
        <div>
            <BeeTabs :items="items" :textAlign="`left`" @changeTab="setCurrentTab" />

            <v-row v-if="isError" class="px-2 py-4">
                <v-alert color="red" dense outlined rounded="xl" text type="error" dismissible @input="closeAlert">
                  {{ getErrorMessage }}
                </v-alert>
            </v-row>

            <GeneralFilter />

            <v-row v-show="currentTab === 0" class="px-2 py-4">
                <GeneralInformation />
            </v-row>
            <v-row v-show="currentTab === 1" class="px-2 py-4">
                <Books />
            </v-row>
            <v-row v-show="currentTab === 2" class="px-2 py-4">
                <Challenges />
            </v-row>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import BeeTabs from '@/components/core/BeeTabs'
import GeneralFilter from '@/components/reports/shared/generalFilter/index.vue'
import GeneralInformation from '@/components/reports/reportsGeneralInformation/index.vue'
import Books from '@/components/reports/reportsBooks/index.vue'
import Challenges from '@/components/reports/reportsChallenges/index.vue'

export default {
    name: 'Dashboard',
    components: {
        BeeTabs,
        GeneralInformation,
        Books,
        Challenges,
        GeneralFilter,
    },
    data() {
        return {
            items: [],
        }
    },
    computed: {
        ...mapState('reports', [
            'currentTab'
        ]),
        ...mapGetters('reports', [
            'isError',
            'getErrorMessage',
        ])
    },
    created() {
        this.items = [
            {
                icon: 'mdi-chart-timeline-variant-shimmer',
                text: this.$t('reports.dashboard.sectionTitles.generalInformation'),
                tooltip: {
                    icon: 'mdi-information',
                    message: this.$t(
                        'reports.dashboard.tooltips.generalInformation.generalInformationTab'
                    ),
                },
            },
            {
                icon: 'mdi-book-open',
                text: this.$t('reports.dashboard.sectionTitles.books'),
                tooltip: {
                    icon: 'mdi-information',
                    message: this.$t(
                        'reports.dashboard.tooltips.booksInformation.bookInformationTab'
                    ),
                },
            },
            {
                icon: 'mdi-file-document-outline',
                text: this.$t('reports.dashboard.sectionTitles.challenges'),
                tooltip: {
                    icon: 'mdi-information',
                    message: this.$t(
                        'reports.dashboard.tooltips.challengesInformation.challengeInformationTab'
                    ),
                },
            },
        ]
    },
    methods: {
        ...mapActions('reports', [
            'clearErrors',
            'setReportPageTab'
        ]),
        setCurrentTab(tab) {
            this.setReportPageTab({ tab })
        },
        closeAlert() {
            this.clearErrors()
        }
    },
    beforeUpdate() {
        this.items = [
            {
                icon: 'mdi-chart-timeline-variant-shimmer',
                text: this.$t('reports.dashboard.sectionTitles.generalInformation'),
                tooltip: {
                    icon: 'mdi-information',
                    message: this.$t(
                        'reports.dashboard.tooltips.generalInformation.generalInformationTab'
                    ),
                },
            },
            {
                icon: 'mdi-book-open',
                text: this.$t('reports.dashboard.sectionTitles.books'),
                tooltip: {
                    icon: 'mdi-information',
                    message: this.$t(
                        'reports.dashboard.tooltips.booksInformation.bookInformationTab'
                    ),
                },
            },
            {
                icon: 'mdi-file-document-outline',
                text: this.$t('reports.dashboard.sectionTitles.challenges'),
                tooltip: {
                    icon: 'mdi-information',
                    message: this.$t(
                        'reports.dashboard.tooltips.challengesInformation.challengeInformationTab'
                    ),
                },
            },
        ]
    },
}
</script>

<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="loading ? [] : items"
      :items-per-page="itemsPerPage"
      :hide-default-footer="hideDefaultFooter"
      :sort-by="sortByHeaders"
      :sort-desc="sortByDesc"
      class="elevation-0 reports__table"
      data-cy="table-container-table"
      :loading="loading"
      height="100%"
    >

      <!-- ------------------------------- -->
      <!-- --- With Student Data --- -->
      <!-- ------------------------------- -->
      <template v-if="withStudent || withStudentName" v-slot:item.student="{ item }" >
          <span v-if="studentNameCallback" @click="studentNameCallback(item.student.id)" class="student-name body-2 deep-purple--text lighten-5 text-decoration-underline text-capitalize"  data-cy="table-studentName-link">
            {{ String(item.student.name).toLowerCase() }}
          </span>
          <span v-else class="body-2 deep-purple--text lighten-5 text-capitalize"  data-cy="table-studentName-link">
            {{ String(item.student.name).toLowerCase() }}
          </span>

      </template>

      <template v-if="withStudent" v-slot:item.type="{ item }">
          <span v-if="item.type" class="text-no-wrap">
            {{ item.type }}
          </span>
          <span v-else>
            -
          </span>
      </template>

      <template v-if="withStudent" v-slot:item.beeStage="{ item }">
          <span v-if="item.beeStage">
            {{ item.beeStage }}
          </span>
          <span v-else>
            -
          </span>
      </template>

      <!-- ------------------------------- -->
      <!-- --- Performance Skills Data --- -->
      <!-- ------------------------------- -->
      <template v-if="withPerformanceSkills" v-slot:item.locate="{ item }" >
        <v-tooltip v-if="item.locate != null && item.locate.totalAnswers != 0" right color="grey lighten-5">
          <template v-slot:activator="{ on, attrs }">
            <span class="d-flex flex-row align-center justify-center"  v-bind="attrs" v-on="on" data-cy="table-locateSkill-span">
              {{ item.locate.average.toFixed(0) }}% <v-progress-linear class="mx-3" :value="item.locate.average" :color="getSkillColor('locate')" rounded height="10px"></v-progress-linear>
            </span>
          </template>
          <p class="black--text font-weight-regular" data-cy="table-locateSkillMsg-p">
            <v-badge inline dot left color="#828DF6" ></v-badge>
            {{item.locate.corrects}} {{ $t('reports.utils.of') }} {{item.locate.total}} <span class="font-weight-bold">{{item.locate.average}}%</span>
          </p>
        </v-tooltip>
        <span v-else class="text-center" data-cy="table-locateSkillNull-span"> - </span>

      </template>

      <template v-if="withPerformanceSkills" v-slot:item.integrate="{ item }" >
        <v-tooltip v-if="item.integrate != null && item.integrate.totalAnswers != 0" right color="grey lighten-5">
          <template v-slot:activator="{ on, attrs }">
            <span class="d-flex flex-row align-center justify-center"  v-bind="attrs" v-on="on" data-cy="table-integrateSkill-span">
              {{ item.integrate.average.toFixed(0) }}% <v-progress-linear class="mx-3" :value="item.integrate.average" :color="getSkillColor('integrate')" rounded height="10px"></v-progress-linear>
            </span>
          </template>
          <p class="black--text font-weight-regular" data-cy="table-integrateSkillMsg-p">
            <v-badge inline dot left color="#65C2FD" ></v-badge>
            {{item.integrate.corrects}} {{ $t('reports.utils.of') }} {{item.integrate.total}} <span class="font-weight-bold">{{item.integrate.average}}%</span>
          </p>
        </v-tooltip>
        <span v-else class="text-center" data-cy="table-integrateSkillNull-span"> - </span>

      </template>

      <template v-if="withPerformanceSkills" v-slot:item.reflect="{ item }" >
        <v-tooltip v-if="item.reflect != null && item.reflect.totalAnswers != 0" right color="grey lighten-5">
          <template v-slot:activator="{ on, attrs }">
            <span class="d-flex flex-row align-center justify-center"  v-bind="attrs" v-on="on" data-cy="table-reflectSkill-span">
              {{ item.reflect.average.toFixed(0) }}% <v-progress-linear class="mx-3" :value="item.reflect.average" :color="getSkillColor('reflect')" rounded height="10px"></v-progress-linear>
            </span>
          </template>
          <p class="black--text font-weight-regular" data-cy="table-reflecSkillMsg-p">
            <v-badge inline dot left color="#6DCDDD"></v-badge>
            {{item.reflect.corrects}} {{ $t('reports.utils.of') }} {{item.reflect.total}} <span class="font-weight-bold">{{item.reflect.average}}%</span>
          </p>
        </v-tooltip>
        <span v-else class="text-center" data-cy="table-reflectSkillNull-span"> - </span>
      </template>

      <template v-if="withPerformanceSkills" v-slot:item.readingComprehension="{ item }" >
        <span v-if="item.readingComprehension" class="d-flex flex-row align-center justify-center"  v-bind="attrs" v-on="on" data-cy="table-comprehension-span">
          {{ Number(item.readingComprehension).toFixed(0) }}% <v-progress-linear class="mx-3" :value="item.readingComprehension" :color="getSkillColor('readingComprehension')" rounded height="10px"></v-progress-linear>
        </span>
        <span v-else class="text-center" data-cy="table-comprehensionNull-span"> - </span>
      </template>

      <!-- ------------------------------- -->
      <!-- --- With Book Data --- -->
      <!-- ------------------------------- -->

      <template v-if="withBookData" v-slot:item.assignment="{ item }" >
        <router-link :to="bookUrl(item)" class=" text-center" data-cy="table-bookProgress-router-link">
          {{ item.assignment.bookTitle }}
        </router-link>
      </template>

      <template v-if="withBookData" v-slot:item.lexileMeasure="{ item }" >
        <span v-if="item.lexileMeasure != null"  class="d-flex flex-row align-center justify-center"  data-cy="table-bookProgress-span">
          {{ item.lexileMeasure }}
        </span>
        <span v-else class="text-center" data-cy="table-bookProgressNull-span"> - </span>
      </template>

      <template v-if="withBookData || withBookProgress" v-slot:item.bookProgress="{ item }" >
        <span v-if="item.bookProgress"  class="d-flex flex-row align-center justify-center" data-cy="table-bookProgress-span">
          {{ Number(item.bookProgress).toFixed(0) }}% <v-progress-linear class="mx-3" :value="item.bookProgress" color="#6DCDDD" rounded height="10px"></v-progress-linear>
        </span>
        <span v-else class="text-center" data-cy="table-bookProgressNull-span"> - </span>
      </template>

      <!-- ------------------------------- -->
      <!-- --- With Reading Time --- -->
      <!-- ------------------------------- -->
      <template v-if="withReadingTime" v-slot:item.readingTime="{ item }" >
          <span v-if="item.readingTime" class="d-flex flex-row align-center justify-center" data-cy="table-readingTime-span">
            <v-icon color="primary" small>
              mdi-timer
            </v-icon> {{ item.readingTime }}
          </span>
          <span v-else class="d-flex flex-row align-center justify-center" data-cy="table-readingTime-span">
            -
          </span>
      </template>

      <!-- ------------------------------- -->
      <!-- --- With Questions Amount --- -->
      <!-- ------------------------------- -->
      <template v-if="withQuestionsAmount" v-slot:item.skill="{ item }" >
          <span class="d-flex flex-row text-center align-center justify-start position-relative my-5">
            <v-sheet :color="getSkillColor(item.skill)" elevation="0" outlined height="30px" width="5px" class="mr-2"></v-sheet>
            {{ $t(`reports.skills.fullName.${item.skill}`) }}
          </span>
      </template>

      <!-- ------------------------------- -->
      <!-- --- With Number Of Questions --- -->
      <!-- ------------------------------- -->
      <template v-if="withNumberOfQuestions" v-slot:item.lectures="{ item }" >
            {{ $t(`reports.tableValues.lectures.${item.lectures}`) }}
      </template>
    </v-data-table>
  </div>
</template>

<script>

export default {
  name: 'Table',
  props: {
    headers: { type: Array, default: () => [] },
    sortByHeaders: { type: Array, default: () => [] },
    sortByDesc: { type: Array, default: () => [] },
    items: { type: Array, default: () => [] },
    itemsPerPage: { type: [String, Number], default: -1 },
    hideDefaultFooter: { type: Boolean, default: true },
    isGeneralInformationReport: { type: Boolean, default: false },
    isBooksReport: { type: Boolean, default: false },
    withPerformanceSkills: { type: Boolean, default: false },
    withTierData: { type: Boolean, default: false },
    withStudent: { type: Boolean, default: false },
    withStudentName: { type: Boolean, default: false },
    withReadingTime: { type: Boolean, default: false },
    withBookData: { type: Boolean, default: false },
    withQuestionsAmount: { type: Boolean, default: false },
    withBookProgress: { type: Boolean, default: false },
    withNumberOfQuestions: { type: Boolean, default: false },
    studentNameCallback: { type: Function, default: () => {}, required: false },
    loading: { type: Boolean, default: false, required: true },
    selfLectureBooks: { type: Boolean, default: false },
  },
  methods: {
    headerCols(header) {
      return header.colspan ? header.colspan : 2
    },
    defineChipTierColor(tierLevel) {
      switch (tierLevel) {
        case 1:
          return 'green darken-2'
        case 2:
          return 'amber accent-3'
        case 3:
        return 'red darken-2'
        default:
          return null
      }
    },
    getSkillColor(skill) {
    switch (skill) {
        case 'locate':
          return '#828DF6'
        case 'integrate':
          return '#65C2FD'
        case 'reflect':
          return '#6DCDDD'
        case 'readingComprehension':
          return 'primary'
        default:
          return 'primary'
      }
    },
    bookUrl(item) {
      return (this.withBookData && this.selfLectureBooks) ? `/teacher/library/book/${item.bookId}` : `/teacher/library/stats/${item.assignment.id}`
    },
  },

}
</script>
<style scope lang="scss">
@import '../../../../assets/style/colors.scss';

.headers-table {
  background: $purple-primary !important;
}

.reports__table .v-data-table-header {
  background-color: $purple-primary;
}
.reports__table .v-data-table-header .sortable.active {
  background-color: #D7D2EF;
}
.v-data-table__wrapper > table > thead > tr:last-child > th {
  border-bottom: none !important;
}
.student-name {
  cursor: pointer;
}

.text-no-wrap {
  word-break: keep-all;
}

</style>

<template>
  <v-card :class="`rounded-xl mb-4 ${fullHeight ? 'h-100' : ''}`" elevation="0" data-cy="card-container-article">
    <v-card-title>
      <span class="mr-2 text-h6 font-weight-bold" data-cy="card-title-label">{{ title }}</span>
      <v-tooltip bottom right color="white" content-class="px-6 py-6 elevation-12 rounded-lg" max-width="270px">
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            color="primary"
            small
            v-bind="attrs"
            v-on="on"
          >
            mdi-information
          </v-icon>
        </template>
        <span data-cy="card-tooltipMessage-span" class="font-weight-regular black--text">{{ tooltipMsg }}</span>
      </v-tooltip>
    </v-card-title>
    <hr />
    <v-card-text data-cy="card-content-p">
      <slot name="content" />
    </v-card-text>

    <!-- Add a footer into the component -->
    <v-card-actions>
    </v-card-actions>
  </v-card>
</template>

<script>

export default {
  name: 'Card',
  props: {
    title: { type: String, default: '' },
    tooltipMsg: { type: String, default: 'Default Msg' },
    fullHeight: { type: Boolean, default: false, required: false }
  }
}

</script>

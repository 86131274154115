<template>
    <v-row class="d-flex flex-column px-5">
        <span
            class="grey--text lighten-3 body-2 font-weight-medium"
            data-cy="generalFilter-studentsTitle-span"
        >
            {{ $t('reports.generalFilter.filters.students') }}
        </span>
        <!-- Students Select Dropdown -->
        <v-select
            outlined
            :items="filteredStudents"
            v-model="currentStudentID"
            item-value="id"
            item-text="firstname"
            color="primary"
            prepend-inner-icon="mdi-account"
            hide-details
            hidden
            :no-data-text="$t('reports.tableValues.emptyValues.emptyStudents')"
            placeholder="Select a student"
            dense
            small-chips
            :cache-items="false"
            data-cy="generalFilter-studentSelect-select"
        >
            <!-- Filter students -->
            <template v-slot:prepend-item>
                <v-col class="pa-5">
                    <span
                        class="body-2 font-weight-bold "
                        data-cy="generalFilter-studentsDetailTitle-span"
                    >
                        {{ $t('reports.generalFilter.labels.filterByStudents') }}
                    </span>
                    <v-text-field
                        data-cy="generalFilter-studentsFilter-input"
                        v-model="searchStudent"
                        append-icon="mdi-magnify"
                        placeholder="Search for student"
                        outlined
                        color="deep-purple lighten-3"
                        hide-details
                        dense
                        class="mt-3 text-capitalize"
                    ></v-text-field>
                </v-col>
            </template>

            <template v-slot:selection="{ item }">
                <div color="deep-purple lighten-5" class="w-100">
                    <v-chip
                        small
                        label
                        color="deep-purple lighten-5 deep-purple--text"
                        class="text-capitalize"
                        v-if="item.id === 0"
                        > 
                        <span class="text-capitalize">
                            {{ String(item.firstname + ' ' + item.lastname).toLowerCase() }}
                        </span> 
                        </v-chip
                    >
                    <v-chip v-else small label color="primary white--text" class="white--text text-capitalize">
                        <span class="text-capitalize">
                            {{
                                String(item.firstname + ' ' + item.lastname).toLowerCase()
                            }}
                        </span>
                    </v-chip>
                </div>
            </template>

            <template v-slot:item="{ item, attrs, on }">
                <v-list-item v-bind="attrs" v-on="on">
                    <v-list-item-action>
                        <v-radio-group v-model="currentStudentID">
                            <v-radio color="primary" :value="item.id"></v-radio>
                        </v-radio-group>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title :id="attrs['aria-labelledby']">
                            <span class="text-capitalize">
                                {{ String(`${item.firstname} ${item.lastname}`).toLowerCase() }}
                            </span>
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </template>
        </v-select>
    </v-row>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'

export default {
    name: 'StudentPicker',
    data() {
        return {
            searchStudent: '',
            currentStudentID: undefined,
        }
    },
    computed: {
        ...mapState('reports', [
            'courses',
            'courseSelected',
            'students',
            'generalInformation',
            'studentsPerCourse',
            'filteredStudents',
            'studentSelected',
            'tierSelected',
            'dateOptionSelected',
        ]),
        ...mapState('userData', {
            teacherCourses: 'courses',
            teacherStudents: 'students',
        }),
        ...mapGetters('reports', ['studentsFromCourse', 'getGeneralInformationFromCourse']),
        defaultAllStudentsOption() {
            return this.$t('reports.generalFilter.defaultValues.all')
        },
        defaultStudentOption() {
            return {
                id: 0,
                firstname: this.defaultAllStudentsOption,
                lastname: '',
            }
        }
    },
    methods: {
        ...mapActions('reports', [
            'setStudents',
            'setSelectedStudent',
            'setCourses',
            'setCourseSelected',
            'setCurrentFilteredStudents',
        ]),
        async handleDefaultStudents() {
            if (!this.courses.length || !this.courseSelected) await this.handleTeacherCourses()

            this.setStudents(this.teacherStudents)

            this.courses.forEach((course) => {
                this.studentsPerCourse[course.id].unshift(this.defaultStudentOption)
            })

            const filteredStudents = this.studentsFromCourse(this.courseSelected.id)
            this.setCurrentFilteredStudents(filteredStudents)

            this.setSelectedStudent(this.defaultStudentOption.id)
            this.currentStudentID = this.defaultStudentOption.id
        },
        async handleTeacherCourses() {
            if (!this.teacherCourses.length) return

            await this.setCourses(this.teacherCourses)
            await this.setCourseSelected(this.teacherCourses[0])
        },
        getOrdinalTier(tierGroup) {
            switch (tierGroup) {
                case 'TIER_GROUP_1':
                    return 1
                case 'TIER_GROUP_2':
                    return 2
                case 'TIER_GROUP_3':
                    return 3
                default:
                    return 0
            }
        },
    },
    watch: {
        currentStudentID(id) {
            this.setSelectedStudent(id)
        },
        async searchStudent(newValue) {
            const courseStudents = await this.studentsFromCourse(this.courseSelected.id)
            if (!newValue.length) {
                this.setCurrentFilteredStudents(courseStudents)
                return
            }

            const filteredStudents = courseStudents.filter((student) =>
                student.firstname.toLowerCase().includes(newValue.toLowerCase())
            )
            if(!filteredStudents.find(student => student.id === 0)) {
                filteredStudents.unshift(this.defaultStudentOption)
            }
            this.setCurrentFilteredStudents(filteredStudents)
        },
        studentSelected(newStudent) {
            if (this.currentStudentID !== newStudent.id) this.currentStudentID = newStudent.id
        },
        /**
         * Watching when the Tier selected change to know which students should we render in
         * the students picker filter
         */
        tierSelected(currentTier) {
            const courseStudents = this.studentsFromCourse(this.courseSelected.id)
            if (currentTier.tier === 0) {
                this.setCurrentFilteredStudents(courseStudents)
                this.currentStudentID = this.defaultStudentOption.id
                this.setSelectedStudent(this.defaultStudentOption.id)
                return
            }

            // The tiers of the studetns are picked by the general information
            const generalInformation =
                this.getGeneralInformationFromCourse({
                    courseID: this.courseSelected.id,
                    dateRangeOption: this.dateOptionSelected,
                }) || []
            const tierStudents = []

            courseStudents.forEach((student) => {
                const currGeneralInformation = generalInformation.find(
                    (generalData) => generalData.studentInfo.id === student.id
                )
                if (!currGeneralInformation) return
                const ordinalTier = this.getOrdinalTier(
                    currGeneralInformation.studentInfo.studentTier
                )
                if (ordinalTier === this.tierSelected.tier) {
                    tierStudents.push(student)
                }
            })

            tierStudents.unshift(this.defaultStudentOption)

            this.setCurrentFilteredStudents(tierStudents)

            this.currentStudentID = this.defaultStudentOption.id
            this.setSelectedStudent(this.defaultStudentOption.id)
        },
        // Changing the name of the default student option
        '$i18n.locale': function() {
            const defaultStudent = this.filteredStudents.find(student => student.id === this.defaultStudentOption.id)
            if(defaultStudent) {
                defaultStudent.firstname = this.defaultAllStudentsOption
            }
        }
    },
    async beforeMount() {
        await this.handleDefaultStudents()
    },
}
</script>

import { Student } from '@/data/dto/student'
import { ChallengeInformationData, GeneralInformation } from '@/data/reports/responses'
import { NFQTableData, ReportTableData } from '@/data/reports/tables'
import Vue from 'vue'

const mapStudentsData = Vue.extend({
    methods: {
        getOrdinalTier(tierGroup: string) {
            switch (tierGroup) {
                case 'TIER_GROUP_1':
                    return 1
                case 'TIER_GROUP_2':
                    return 2
                case 'TIER_GROUP_3':
                    return 3
                default:
                    return 0
            }
        },
        getPFSNullStudentData(student: Student): ReportTableData {
            return {
                student: {
                    id: student.id,
                    name: `${student.firstname} ${student.lastname}`
                },
                tier: 0,
                readingComprehension: null,
                integrate: null,
                locate: null,
                reflect: null,
                type: null
            }
        },
        getPFSStudentData(student: Student, generalInformation: GeneralInformation): ReportTableData {
            return {
                student: {
                    id: student.id,
                    name: `${student.firstname} ${student.lastname}`
                },
                tier: this.getOrdinalTier(generalInformation.studentInfo?.studentTier || ''),
                readingComprehension: generalInformation.bookAndChallengeDTO.bookAndChallengeReadingComprehensionAverage ? Math.round(generalInformation.bookAndChallengeDTO.bookAndChallengeReadingComprehensionAverage) : null,
                integrate: generalInformation.bookAndChallengeDTO.allBookAndChallengeInterpretationSkillAnswers ? {
                    corrects: Math.round(generalInformation.bookAndChallengeDTO.allBookAndChallengeCorrectInterpretationSkillAnswers) || 0,
                    total: Math.round(generalInformation.bookAndChallengeDTO.allBookAndChallengeInterpretationSkillAnswers) || 0,
                    average: generalInformation.bookAndChallengeDTO.avgBookAndChallengeInterpretationSkill && Math.round(generalInformation.bookAndChallengeDTO.avgBookAndChallengeInterpretationSkill),
                } : null,
                locate: generalInformation.bookAndChallengeDTO.allBookAndChallengeLocateSkillAnswers ? {
                    corrects: Math.round(generalInformation.bookAndChallengeDTO.allBookAndChallengeCorrectLocateSkillAnswers) || 0,
                    total: Math.round(generalInformation.bookAndChallengeDTO.allBookAndChallengeLocateSkillAnswers) || 0,
                    average: generalInformation.bookAndChallengeDTO.avgBookAndChallengeLocateSkill && Math.round(generalInformation.bookAndChallengeDTO.avgBookAndChallengeLocateSkill),
                } : null,
                reflect: generalInformation.bookAndChallengeDTO.allBookAndChallengeRefAndValSkillAnswers ? {
                    corrects: Math.round(generalInformation.bookAndChallengeDTO.allBookAndChallengeCorrectRefAndValSkillAnswers) || 0 || 0,
                    total: Math.round(generalInformation.bookAndChallengeDTO.allBookAndChallengeRefAndValSkillAnswers) || 0,
                    average: generalInformation.bookAndChallengeDTO.avgBookAndChallengeRefAndValSkill && Math.round(generalInformation.bookAndChallengeDTO.avgBookAndChallengeRefAndValSkill),
                } : null,
                beeStage: generalInformation.studentInfo?.beereadersStage || null,
                type: generalInformation.studentInfo?.readerType || null
            }
        },
        getAllPFSData(students: Student[], generalInformationData: GeneralInformation[]): ReportTableData[] {
            const PFSData: ReportTableData[] = []

            students.forEach(student => {
                if(student.id === 0) {
                    return
                }
                const generalInformationByStudent = generalInformationData.find(generalInformation => generalInformation.studentInfo?.id === student.id)

                if(!generalInformationByStudent) {
                    PFSData.push(this.getPFSNullStudentData(student))
                } else {
                    PFSData.push(this.getPFSStudentData(student, generalInformationByStudent))
                }
            })

            return PFSData
        },
        getStudentPFSData(student: Student, generalInformation: GeneralInformation[]): ReportTableData[] {
            const PFSData:ReportTableData[] = []
            const currGeneralInformation = generalInformation.find(generalInfo => generalInfo.studentInfo?.id === student.id)
            currGeneralInformation ?  PFSData.push(this.getPFSStudentData(student, currGeneralInformation)) : PFSData.push(this.getPFSNullStudentData(student))
            return PFSData
        },
        // NFQ Table methods
        getTotalSum(values: number[]): number {
            if(!values.length) return 0

            return values.reduce((prev, next) => prev + next)
        },
        getTotalAmountOfChallenges(challengeInformation: ChallengeInformationData) {
            const totalIntegrateChallenges = challengeInformation.interpretationChallengeSkillData.totalChallenges
            const totalLocateChallenges = challengeInformation.locateChallengeSkillData.totalChallenges
            const totalReflectChallenges = challengeInformation.refAndValChallengeSkillData.totalChallenges
            return this.getTotalSum([totalIntegrateChallenges, totalLocateChallenges, totalReflectChallenges])
        },
        getTotalCorrectAnswersOfChallenges(challengeInformation: ChallengeInformationData) {
            const totalIntegrateChallenges = challengeInformation.interpretationChallengeSkillData.totalCorrectAnswers
            const totalLocateChallenges = challengeInformation.locateChallengeSkillData.totalCorrectAnswers
            const totalReflectChallenges = challengeInformation.refAndValChallengeSkillData.totalCorrectAnswers
            return this.getTotalSum([totalIntegrateChallenges, totalLocateChallenges, totalReflectChallenges])
        },
        getTotalIncorrectAnswersOfChallenges(challengeInformation: ChallengeInformationData) {
            const totalIntegrateChallenges = challengeInformation.interpretationChallengeSkillData.totalIncorrectAnswers
            const totalLocateChallenges = challengeInformation.locateChallengeSkillData.totalIncorrectAnswers
            const totalReflectChallenges = challengeInformation.refAndValChallengeSkillData.totalIncorrectAnswers
            return this.getTotalSum([totalIntegrateChallenges, totalLocateChallenges, totalReflectChallenges])
        },
        getTotalAnswersOfChallenges(challengeInformation: ChallengeInformationData) {
            const totalIntegrateChallenges = challengeInformation.interpretationChallengeSkillData.totalAnswers
            const totalLocateChallenges = challengeInformation.locateChallengeSkillData.totalAnswers
            const totalReflectChallenges = challengeInformation.refAndValChallengeSkillData.totalAnswers
            return this.getTotalSum([totalIntegrateChallenges, totalLocateChallenges, totalReflectChallenges])
        },
        getAllNFQData(generalInformation: GeneralInformation[], tierSelected?: number): NFQTableData[] {
            if(tierSelected) {
                generalInformation = generalInformation.filter(generalInfo => this.getOrdinalTier(generalInfo.studentInfo?.studentTier || '') === tierSelected)
            }
            return [
                {
                    lectures: 'assignedBooks',
                    amount: generalInformation[0]?.bookAndChallengeDTO?.book?.totalBooks || 0,
                    corrects: this.getTotalSum(generalInformation.map(info => info.bookAndChallengeDTO?.book?.totalCorrectAnswers || 0)),
                    incorrects: this.getTotalSum(generalInformation.map(info => info.bookAndChallengeDTO?.book?.totalIncorrectAnswers || 0)),
                    total: this.getTotalSum(generalInformation.map(info => info.bookAndChallengeDTO?.book?.totalAnswers || 0)),
                },
                {
                    lectures: 'readChallenges',
                    amount: this.getTotalSum(generalInformation.map(generalInfo => generalInfo.challengesInfo ? generalInfo.challengesInfo.totalChallenges : 0)),
                    corrects: this.getTotalSum(generalInformation.map(generalInfo => generalInfo.challengesInfo ? this.getTotalCorrectAnswersOfChallenges(generalInfo.challengesInfo) : 0)),
                    incorrects: this.getTotalSum(generalInformation.map(generalInfo => generalInfo.challengesInfo ? this.getTotalIncorrectAnswersOfChallenges(generalInfo.challengesInfo) : 0)),
                    total: this.getTotalSum(generalInformation.map(generalInfo => generalInfo.challengesInfo ? this.getTotalAmountOfChallenges(generalInfo.challengesInfo) : 0)),
                },
            ]
        },
        getStudentNQFData(student: Student, generalInformation: GeneralInformation[]): NFQTableData[] {

            const currGeneralInformation = generalInformation.find(generalInfo => generalInfo.studentInfo?.id === student.id)

            if(!currGeneralInformation) return [
                {
                    lectures: 'assignedBooks',
                    amount: 0,
                    total: 0,
                    corrects: 0,
                    incorrects: 0
                },
                {
                    lectures: 'readChallenges',
                    amount: 0,
                    total: 0,
                    corrects: 0,
                    incorrects: 0
                },
            ]

            return [
                {
                    lectures: 'assignedBooks',
                    amount: currGeneralInformation.bookInfo?.totalStudentBooks || 0,
                    corrects: currGeneralInformation.bookInfo?.totalCorrectAnswers || 0,
                    incorrects: currGeneralInformation.bookInfo?.totalIncorrectAnswers || 0,
                    total: currGeneralInformation.bookInfo?.totalAnswers || 0,
                },
                {
                    lectures: 'readChallenges',
                    amount: currGeneralInformation.challengesInfo ? currGeneralInformation.challengesInfo.totalChallenges : 0,
                    corrects: currGeneralInformation.challengesInfo ? this.getTotalCorrectAnswersOfChallenges(currGeneralInformation.challengesInfo) : 0,
                    incorrects: currGeneralInformation.challengesInfo ? this.getTotalIncorrectAnswersOfChallenges(currGeneralInformation.challengesInfo) : 0,
                    total: currGeneralInformation.challengesInfo ? this.getTotalAnswersOfChallenges(currGeneralInformation.challengesInfo) : 0,
                },
            ]
        }
    }
})

export default mapStudentsData